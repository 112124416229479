<template>
  <div class="dataContainer">
    <el-button @click="add" type="primary" size="medium">新增</el-button>
    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="链接" prop="attachment_url"></el-table-column>
      <!-- <el-table-column label="类型" prop="type"></el-table-column> -->
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small"
            >编辑</el-button
          >
    
          <el-button @click="remove(scope.row)" type="danger" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="编辑附件信息"
      :visible.sync="showAdd"
      :close-on-click-modal="false"
      :append-to-body="false"
    >
      <el-form class="myEditForm">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="文件地址">
          <el-input v-model="form.attachment_url" disabled></el-input>
          <el-upload
            action="/"
            :show-file-list="false"
            :before-upload="(file) => beforeAvatarUpload(file)"
          >
            <el-button size="small" type="primary" :loading="loading"
              >点击上传</el-button
            >
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="类型">
          <el-select v-model="form.type">
            <el-option value="PDF"></el-option>
            <el-option value="PPT"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <template slot="footer">
        <el-button type="primary" size="medium" @click="confirm"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
    
    <script>
import {
  getSessionPPTAttachments,
  updateSessionPPTAttachment,
  removeSessionPPTAttachment,
  getQiniuToken,
} from "../../api/api";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      showAdd: false,
      id: "",
      loading: false,
      ppt_id: this.$route.query.id,
      session_id: this.$route.query.session_id,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getSessionPPTAttachments({
        ppt_id:this.ppt_id,
        session_id: this.session_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    add() {
      this.showAdd = true;
      this.form = {
        name: "",
        attachment_url: "",
      };
      this.id = "";
    },
    edit(data) {
      this.form = {
        name: data.name,
        attachment_url: data.attachment_url,
      };
      this.id = data._id.$id;
      this.showAdd = true;
    },
    confirm() {
      const loading = this.$loading({
        lock: true,
        text: "文件上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      updateSessionPPTAttachment({
        id: this.id,
        ppt_id:this.ppt_id,
        session_id: this.session_id,
        ...this.form,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success("更新成功");
            this.showAdd = false;
            this.fetchData();
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    remove(data) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeSessionPPTAttachment({ id: data._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功");
            this.fetchData();
          }
        });
      });
    },
    async beforeAvatarUpload(file) {
      this.loading = true
      let fileName = file.name.split(".")[0];
      this.form.name = fileName;
      let timestamp = new Date().getTime();
      this.form.file_name = timestamp;
      const result = await getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(file, timestamp+'.'+file.name.split(".")[1], token);
        const observer = {
          complete: (res) => {
            this.form.attachment_url =
              "https://mainbucket.reachable-edu.com/" + res.key;
              this.loading = false
          },
        };
        this.observable.subscribe(observer);
      }
    },
  },
};
</script>
    
<style lang="scss" scoped>
.dataContainer {
  width: 100%;
  padding: 30px;
}
</style>
    <style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px !important;
  text-align: center;
}
.avatar-uploader-icon1 {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
</style>
    